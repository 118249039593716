* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;

  font-family: "Titillium Web", sans-serif;
  overflow-x: hidden;
  /*background-color: #fff7ed;*/
}

body {
  scroll-behavior: smooth;
}

.text-align-center {
  text-align: center;
}
.flex {
  display: flex;
}

.direction-column {
  flex-direction: column;
}

.align {
  align-items: center;
}

.justify {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}
.min-gap {
  gap: 1rem;
}

.header {
  padding: 2rem 4.2rem;
}

.border-radius {
  border-radius: 2px !important;
}
@media (max-width: 1000px) {
  html {
    font-size: 9px;
  }
}

@media (max-width: 700px) {
  html {
    font-size: 8px;
  }
}

/* FONT FACES*/

@font-face {
  font-family: "Aleo";
  src: url("/public/fonts/Aleo/Aleo-VariableFont_wght.ttf") format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "Blinker";
  src: url("/public/fonts/Blinker/Blinker-Regular.ttf") format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: "Inter";
  src: url("/public/fonts/Inter/Inter-VariableFont_slnt\,wght.ttf")
    format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "Oswald";
  src: url("/public/fonts/Oswald/Oswald-VariableFont_wght.ttf")
    format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "Poppins";
  src: url("/public/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "Staatliches";
  src: url("/public/fonts/Staatliches/Staatliches-Regular.ttf")
    format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "Teko";
  src: url("/public/fonts/Teko/Teko-VariableFont_wght.ttf") format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "Ubuntu";
  src: url("/public/fonts/Ubuntu/Ubuntu-Regular.ttf") format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "Roboto";
  src: url("/public/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
